<template>
  <v-alert v-if="isB2C"
      border="top"
      type="warning"
      variant="outlined"
      prominent
      style="margin-top: 1rem;"
    >
    We are upgrading our billing systems, which may cause temporary subscription issues. If you experience any problems, please contact our   <a :href="`mailto:${supportEmail}`" target="_blank">support team</a>.

    </v-alert>
  <div v-if="this.loading">
    <app-loader />
  </div>
  <div v-else>
    <div v-if="subscriptionIsActive">
      <v-alert class="mx-5 mt-4" density="compact" type="success" variant="tonal" text :icon="false" v-if="!cancelAtPeriodEnd">
        <v-row class="align-center no-wrap">
          <v-col cols="12" sm="8">
            <div class="alert_messaging">
              <label>{{ this.$t("Settings.active_subscription_until") }}: {{ nextPayment }}.</label>
              <label>
                {{ this.$t("Settings.update_card_details") }}
                <router-link to="/billing" target="_blank">{{ this.$t("Generic.Common.here") }}</router-link>
              </label>
            </div>
          </v-col>
          <v-col cols="12" sm="4" class="text-right">
            <v-btn variant="text" color="primary" class="cancel_btn" @click="showCancelModal()">
              {{ $t("plans.Common.cancel_subscription") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert class="mx-5 mt-4" density="compact" type="success" variant="tonal" text :icon="false" v-else>
        <v-row class="align-center no-wrap">
          <v-col cols="12" sm="8">
            <div class="alert_messaging">
              <label>{{ this.$t("Settings.cancel_subscription_at") }}: {{ nextPayment }}.</label>
              <label>
                {{ this.$t("Settings.update_card_details_reactivate_subscription") }}
                <router-link to="/billing" target="_blank">{{ this.$t("Generic.Common.here") }}</router-link>
              </label>
            </div>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <v-alert class="mx-5 mt-4" density="compact" type="warning" variant="tonal" text :icon="false" v-if="activeTrial">
      <span>{{ this.$t("plans.Common.subscription_expired_in_days", { remainingDays: remainingDays }) }}</span>
    </v-alert>

    <div class="pt-1" style="display: flex; justify-content: center">
      <PricingPlansBoxes @selected="handlePlanSelection" />
    </div>

    <ChangePlan ref="changePlanDialog" />
    <CancelSubscription ref="cancelSubscriptionDialog" @subscriptionCancelled="handleSubscriptionCancelled" />
  </div>
</template>

<script>
import PricingPlansBoxes from "@/components/subscription_plan/PricingPlansBoxes.vue";
import CancelSubscription from "./modals/CancelSubscription.vue";
import ChangePlan from "./modals/ChangePlan.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";
import appSDK from "@/client/AppSDK";
import { usePostHog } from '@/composables/usePosthog';
import { trackPurchase } from "@/helpers/utils/analyticsUtils";

const user = constant.user;
const plans = constant.plans;

const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

const generateSubscriptionId = () => {
  const uuid = generateUUID();
  return uuid;
};

export default {
  components: {
    PricingPlansBoxes,
    CancelSubscription,
    ChangePlan,
  },

  data() {
    return {
      newPlanSelected: -1,
      cancelAtPeriodEnd: false,
      nextPayment: null,
      loading: false,
      isFromUrlParam: false,
      subscriptionId: null,
    };
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, justRegistered: user.getters.justRegistered }),
    ...mapGetters("plans", {
      getPlans: plans.getters.GetPlans,
      getStatus: plans.getters.GetStatus,
      error: plans.getters.GetError,
    }),
    supportEmail() {
      return this.$store.getters.configs?.supportEmail;
    },
    status() {
      return this.getStatus;
    },

    user() {
      return this.getUser;
    },

    currentPricingPlan() {
      return this.user?.pricingPlan;
    },

    pricingPlansData() {
      if (this.$store.getters.isB2C) {
        return this.getPlans?.plans;
      } else {
        return this.$store.getters.pricingPlansData;
      }
    },
    isB2C() {
      return this.$store.getters.isB2C;
    },

    subscriptionIsActive() {
      if (!this.currentPricingPlan) {
        return false;
      }
      if (this.user.subscriptionStatus === "trialStart") {
        return false;
      }

      const activePlansData = this.pricingPlansData.find(({ index }) => index === this.currentPricingPlan);

      if (!activePlansData) {
        return false;
      }
      return activePlansData.cost !== 0;
    },

    activeTrial() {
      return this.user?.subscriptionStatus === "trialStart" && this.currentPricingPlan;
    },

    remainingDays() {
      if (this.user.remainingDays <= 0) {
        return 0;
      }
      return this.user.remainingDays;
    },
  },

  async created() {
    if (this.subscriptionIsActive && this.currentPricingPlan > 1) {
      await this.setupActiveSubInfo();
    }
    await this.$nextTick();
    this.checkForSuccessfulPayment();

  },

  methods: {
    async setupActiveSubInfo() {
      try {
        this.loading = true;
        const result = await this.$store.dispatch(constant.user.actions.GetB2CSubscription);
        this.nextPayment = result.nextPayment;
        this.cancelAtPeriodEnd = result.cancelAtPeriodEnd;
      } catch (error) {
        this.$notify({
          message: error.message || this.$t("errors.getSubscription"),
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    
    handleSubscriptionCancelled() {
      this.cancelAtPeriodEnd = true;
    },

    showCancelModal() {
      this.$refs.cancelSubscriptionDialog.open();
    },

    getBaseUrl() {
      return window.location.origin;
    },

    async openStripeCheckout(planData) {
      try {
        this.loading = true;
        const body = {
          priceId: planData.priceId,
          baseUrl: this.getBaseUrl()
        };
        
        const response = await appSDK["stripe.checkout"](body);
        
        // if (this.isFromUrlParam) {
        //   this.isFromUrlParam = false;
        //   window.open(response.url, '_self');
        // } else {
          window.open(response.url, '_self');
        //}
        
        this.$amplitude.logEvent("STRIPE_CHECKOUT_OPENED", {
          priceId: planData.priceId,
          plan: planData.plan,
        });
      } catch (error) {
        console.error(error);
        this.$notify({
          message: this.$t("Generic.Messages.Error.common"),
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },

     handlePlanSelection(newPlan) {
      if (this.subscriptionIsActive && this.currentPricingPlan > newPlan) return;
      if (this.pricingPlansData.find(({ index }) => index === newPlan).cost === 0) return;
      if (newPlan === this.currentPricingPlan) return;

      if (this.currentPricingPlan === 1  ) {
        const planData = this.pricingPlansData.find(({ index }) => index === newPlan);
        this.openStripeCheckout(planData);
      } 
      if(newPlan > this.currentPricingPlan) {
        const { posthog } = usePostHog();
      posthog.capture('upgrade_button_clicked', {
        currentPlan: this.currentPricingPlan,
        newPlan: newPlan,
        timestamp: new Date().toISOString()
      });
        this.$refs.changePlanDialog.open(newPlan);
      }
    },

    async checkForSuccessfulPayment() {
      this.loading = true;

      const queryParam = this.$route.query.subscriptionStatus;

      if (queryParam === "success") {
        try {
          await new Promise(resolve => setTimeout(resolve, 5000));
          await this.$store.dispatch(user.actions.Auth);
          await this.$store.dispatch(user.actions.RefreshUserData);

          // this.$notify({
          //   message: this.$t("plans.Common.success_payment"),
          //   type: "success",
          // });

          this.$emit("payment-success");
        
          this.subscriptionId = generateSubscriptionId();
          
          const planData = this.pricingPlansData.find(plan => plan.index === this.user.pricingPlan);
          trackPurchase(
            this.subscriptionId,
            planData?.cost || 0,
            planData?.currency || 'USD'
          );
          this.$amplitude.logEvent("ALTERNATE_PAYMENT_SUCCESS");
          //await this.setupActiveSubInfo();

          this.$router.replace({ query: {} });

          await this.$nextTick();
          await new Promise(resolve => setTimeout(resolve, 500));

          this.$router.replace({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              status: "successful-payment",
            },
          });
        } catch (error) {
          this.$notify({
            message: error.message || this.$t("errors.unexpected"),
            type: "danger",
          });
        } finally {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
  },
  
  async mounted() {
    let localStorageJustRegistered = localStorage.getItem("justRegistered");
    if (localStorageJustRegistered) {
      await this.$store.dispatch(user.actions.SetJustRegistered, false);
      this.$router.push("/overview");
    }
    if (this.justRegistered) {
      localStorage.setItem("justRegistered", this.justRegistered);
    }
    
    this.$nextTick(async () => {
      const planParam = parseInt(this.$route.query.plan);
      if (planParam && !isNaN(planParam)) {
        if (this.pricingPlansData && this.pricingPlansData.length > 0) {
          this.isFromUrlParam = true;
          await this.handlePlanSelection(planParam);
        } else {
          const unwatch = this.$watch('pricingPlansData', async (newValue) => {
            if (newValue && newValue.length > 0) {
              this.isFromUrlParam = true;
              await this.handlePlanSelection(planParam);
              unwatch(); 
            }
          });
        }
      }
    });
  },
};
</script>
<style>
.flex-b {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pad-c {
  padding: 1em;
}

.cancelAlert {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert_messaging {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cancel_btn {
  margin-left: auto;
}
</style>
