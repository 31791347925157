const pushToDataLayer = (eventName, eventParams = {}) => {
  if (window.dataLayer && process.env.VUE_APP_MODE === 'b2c' && process.env.VUE_APP_GTM === 'enable') {
    window.dataLayer.push({
      event: eventName,
      ...eventParams
    });
  }
};

export const trackAddToCart = (currency, value) => {
  pushToDataLayer('add_to_cart', {
    currency: currency,
    value: value,
  });
};

export const trackBeginCheckout = () => {
  pushToDataLayer('begin_checkout');
};

export const trackPurchase = (transactionId, value, currency) => {
  pushToDataLayer('purchase', {
    transaction_id: transactionId,
    value: value,
    currency: currency,
  });
};

export const trackSignup = () => {
  pushToDataLayer('signup');
};

export const trackPageView = (pageTitle) => {
  pushToDataLayer('page_view', {
    page_title: pageTitle
  });
};

export const trackCustomEvent = (eventName, params = {}) => {
  pushToDataLayer(eventName, params);
};
